import request from "../plugins/axios";


export function radarIndex(params) {
    return request({
        url: '/radar/index',
        method: 'get',
        params
    })
}

export function radar(data) {
    return request({
        url: '/radar/store',
        method: 'POST',
        data
    })
}

export function radarUpdate(data) {
    return request({
        url: '/radar/update',
        method: 'PUT',
        data
    })
}

export function radarInfo(params) {
    return request({
        url: '/radar/info',
        method: 'GET',
        params
    })
}

export function destroy(data) {
    return request({
        url: '/radar/destroy',
        method: 'DELETE',
        data
    })
}

//生成分享链接
export function storeChannelLink(data) {
    return request({
        url: '/radar/storeChannelLink',
        method: 'POST',
        data
    })
}

//新增发送记录
export function storeSendRecord(data) {
    return request({
        url: '/radar/storeSendRecord',
        method: 'POST',
        data
    })
}

//首页统计分类
export function staticChartType(params) {
    return request({
        url: '/radar/staticChartType',
        method: 'GET',
        params
    })
}

//首页统计
export function staticChart(params) {
    return request({
        url: '/radar/staticChart',
        method: 'GET',
        params
    })
}


//统计详情
export function staticDetail(params) {
    return request({
        url: '/radar/staticDetail',
        method: 'GET',
        params
    })
}

//统计详情
export function InfoDetail(params) {
    return request({
        url: '/radar/InfoDetail',
        method: 'GET',
        params
    })
}
