<template>
  <div class="index">
    <div class="title-serch">
      <van-search
          style="flex: 1;"
          v-model="serchValue"
          show-action
          placeholder="搜索素材名称"
          shape="round"
          @search="onSearch"
      >
        <template #action>
          <div @click="shows = true" style="display: flex;align-items: center; color:#2b60dd;">
            <img style="width: 24px;height: 24px;" src="../../assets/images/add-plue.svg"/>
          </div>
        </template>
      </van-search>
    </div>
    <div class="content">
      <van-tabs v-model="active" style="margin-top: 10px;" swipe-threshold="7" color="#1890ff"
                title-active-color="#1890ff" @change="changeActive">
        <van-tab title="链接">
          <div class="item">
            <div v-if="listData.length > 0">
              <div v-for="(i,is) in listData" @click="details(i)" :key="is" class="items">
                <div class="title">
                  <img
                      :src="i.link_cover"/>
                  <div class="share">
                    <div>
                      {{ i.link_title }}
                    </div>
                    <div>
                      <span>{{ i.link_description }}</span>
                    </div>
                  </div>
                </div>
                <div class="share-button">
                  <div @click.stop="editShow = true,fileId = i.id">
                    <van-icon name="more-o"/>
                    更多
                  </div>
                  <div
                      @click.stop="shareShow = true,shareDataFile= i,shareData.url = i.link">
                    <img src="../../assets/images/icon-share.svg"/>
                    分享
                  </div>
                </div>
              </div>
            </div>
            <van-empty
                v-else
                class="custom-image"
                :image="require('../../assets/images/emptyimg.png')"
                description="暂无数据"
            />
          </div>
        </van-tab>
        <van-tab title="PDF">
          <!--          <van-dropdown-menu active-color="#1890ff">-->
          <!--            <van-dropdown-item @change="getList" v-model="value1" :options="option1"/>-->
          <!--            <van-dropdown-item @change="getList" v-model="value3" :options="option3"/>-->
          <!--            <van-dropdown-item @change="getList" v-model="value2" :options="option2"/>-->
          <!--          </van-dropdown-menu>-->
          <div class="item">
            <div v-if="listData.length > 0">
              <div v-for="(i,is) in listData" @click="details(i)" :key="is" class="items">
                <div class="title">
                  <img
                      src="../../assets/images/pdf.png"/>
                  <div class="share">
                    <div>
                      {{ i.pdf_name }}
                    </div>
                    <div>
                      <span>转发 {{ i.sendNum || 0 }}</span>
                    </div>
                  </div>
                </div>
                <div class="share-button">
                  <div @click.stop="editShow = true,fileId = i.id">
                    <van-icon name="more-o"/>
                    更多
                  </div>
                  <div
                      @click.stop="shareShow = true,shareDataFile= i,shareData.url = i.pdf">
                    <img src="../../assets/images/icon-share.svg"/>
                    分享
                  </div>
                </div>
              </div>
            </div>
            <van-empty
                v-else
                class="custom-image"
                :image="require('../../assets/images/emptyimg.png')"
                description="暂无数据"
            />
          </div>
        </van-tab>
        <van-tab title="文章">
          <!--          <van-dropdown-menu active-color="#1890ff">-->
          <!--            <van-dropdown-item @change="getList" v-model="value1" :options="option1"/>-->
          <!--            <van-dropdown-item @change="getList" v-model="value3" :options="option3"/>-->
          <!--            <van-dropdown-item @change="getList" v-model="value2" :options="option2"/>-->
          <!--          </van-dropdown-menu>-->
          <div class="item">
            <!--            <van-pull-refresh v-model="refreshing" @refresh="onRefresh">-->
            <!--              <van-list-->
            <!--                  style="height: 100%;"-->
            <!--                  v-model="loading"-->
            <!--                  :finished="finished"-->
            <!--                  finished-text="没有更多了"-->
            <!--                  @load="onLoad"-->
            <!--              >-->
            <div v-if="listData.length > 0">
              <div v-for="(i,is) in listData" @click="details(i)" :key="is" class="items">
                <div class="title">
                  <img
                      :src="i.article.cover_url"/>
                  <div class="share">
                    <div>
                      {{ i.article.title }}
                    </div>
                    <div>
                      <span>转发 {{ i.sendNum || 0 }}</span>
                    </div>
                  </div>
                </div>
                <div class="share-button">
                  <div @click.stop="editShow = true,fileId = i.id">
                    <van-icon name="more-o"/>
                    更多
                  </div>
                  <div
                      @click.stop="shareShow = true,shareDataFile= i,shareData.url = i.article.url">
                    <img src="../../assets/images/icon-share.svg"/>
                    分享
                  </div>
                </div>
              </div>
            </div>
            <!--              </van-list>-->
            <!--            </van-pull-refresh>-->
            <van-empty
                v-else
                class="custom-image"
                :image="require('../../assets/images/emptyimg.png')"
                description="暂无数据"
            />
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <!--  新建  -->
    <van-action-sheet
        v-model="shows"
        cancel-text="取消"
        description="选择素材类型"
        close-on-click-action
    >
      <template slot="default">
        <div @click="onSelect(item)" v-for="(item,index) in actions" :key="index"
             class="checkMarkting van-hairline--bottom">
          <div class="info">
            <p class="fs17 title">{{ item.name }}</p>
          </div>
          <div class="more">
            <img src="../../assets/images/marktingAddeb.svg" class="fs16 svg-icon"
                 style="width: 16px; height: 16px;">
          </div>
        </div>
      </template>
    </van-action-sheet>
    <!--  编辑删除  -->
    <van-action-sheet
        v-model="editShow"
        :actions="editActions"
        cancel-text="取消"
        close-on-click-action
        @cancel="editShow = false"
        @select="editOr"
    />
    <!--  新建  -->
    <van-action-sheet
        v-model="shareShow"
        cancel-text="取消"
        close-on-click-action
        @cancel="shareShow = false"
    >
      <template slot="default">
        <div class="share-icon">
          <div @click="forwardData">
            <img src="../../assets/images/marketSend.svg"/>
            转发
          </div>
          <!--          <div>-->
          <!--            <img src="../../assets/images/wechatMaterial.svg"/>-->
          <!--            微信-->
          <!--          </div>-->
          <div @click="forwardDatap"
               v-if="shareDataFile.type == '图片' || shareDataFile.type == '图文' || shareDataFile.type == '视频'  ">
            <img src="../../assets/images/marketFriend.svg"/>
            客户朋友圈
          </div>
          <div @click="copy" id="copy_text"
               　　　　　　ref="copy"
               　　　　　　　data-clipboard-action="copy"
               　　　　　　:data-clipboard-text="shareData.url">
            <img src="../../assets/images/marketCopy.svg"/>
            复制
          </div>
        </div>
      </template>
    </van-action-sheet>
  </div>
</template>

<script>
import {wechatMoments, forward} from '@/utils/wxCodeAuth'
import Clipboard from 'clipboard'
import {sendChatMessage, shareToExternalMoments} from "../../utils/wxCodeAuth";
import {radarIndex, destroy, storeChannelLink, storeSendRecord} from "../../api/radar";

export default {
  data() {
    return {
      // 文字
      active: 0,
      serchValue: '',
      message: '',
      // tbas切换
      tabsChose: 0,
      value1: 0,
      value2: 1,
      value3: '',
      option1: [
        {text: '全部分组', value: 0},
      ],
      option2: [
        {text: '所有人', value: 1},
        {text: '我自己', value: 2},
      ],
      option3: [
        {text: '上传时间优先', value: ''},
        {text: '发送次数优先', value: 1},
      ],
      // 新建操作
      shows: false,
      actions: [
        {name: '链接', subname: 1},
        {name: 'PDF', subname: 2},
        {name: '文章', subname: 3},
      ],
      // 编辑操作
      editShow: false,
      editActions: [
        {name: '编辑'},
        {name: '删除', color: '#ee0a24'}
      ],
      // 分享操作
      shareShow: false,
      finished: false,
      loading: false,
      refreshing: false,
      perPage: 10000,
      listData: [],
      fileId: '',
      shareData: {
        title: '',
        url: ''
      },
      shareDataFile: {}

    }
  },
  watch: {},
  created() {
    this.getList()
  },
  methods: {
    // 轨迹搜索
    onSearch() {
      this.getList()
    },
    onClickLeft() {
      this.$router.back()
    },
    // 新建
    onSelect(e) {
      this.shows = false
      this.$router.push({
        path: '/radar/create',
        query: {
          type: e.subname
        }
      })
    },
    show() {
      this.shows = true
    },
    onRefresh(e) {
    },
    onLoad() {

    },
    editOr(e) {
      if (e.name == '编辑') {
        this.$router.push({
          path: '/radar/create',
          query: {
            id: this.fileId
          }
        })
      } else {
        this.detele()
      }
    },
    detele() {
      destroy({
        id: this.fileId
      }).then(res => {
        this.$message.success('删除成功')
        this.getList()
      })
    },
    async forwardDatap() {
      this.shareShow = false
      let data = this.shareDataFile
      let shareData = {}
      if (data.type == 1) {
        shareData.title = data.link_title
        shareData.imgUrl = data.link_cover
        shareData.description = data.link_description
      } else if (data.type == 2) {
        shareData.title = data.pdf_name
      } else if (data.type == 3) {
        shareData.title = data.article.title
        shareData.imgUrl = data.article.cover_url
      }
      const res = await storeChannelLink({
        type: data.type,
        radar_id: data.id
      })
      console.log(res);
      shareData.url = res.data.link
      storeSendRecord({
        type: data.type,
        radar_id: data.id
      }).then(res => {
        shareToExternalMoments(shareData).then(res => {
          this.$message.success('转发成功')
        })
      })
    },
    async forwardData() {
      this.shareShow = false
      let data = this.shareDataFile
      let shareData = {}
      if (data.type == 1) {
        shareData.title = data.link_title
        shareData.imgUrl = data.link_cover
        shareData.description = data.link_description
      } else if (data.type == 2) {
        shareData.title = data.pdf_name
      } else if (data.type == 3) {
        shareData.title = data.article.title
        shareData.imgUrl = data.article.cover_url
      }
      const res = await storeChannelLink({
        type: data.type,
        radar_id: data.id
      })
      console.log(res);
      shareData.url = res.data.link
      storeSendRecord({
        type: data.type,
        radar_id: data.id
      }).then(res => {
        forward(shareData).then(res => {
          this.$message.success('转发成功')
        })
      })
    }
    ,
    changeActive(name, title) {
      this.getList()
    }
    ,
    copy() {
      this.shareShow = false
      var clipboard = new Clipboard("#copy_text");
      clipboard.on("success", (e) => {
        this.$message.success('复制成功')

        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        this.$message.error('复制失败')
        // 释放内存
        clipboard.destroy();
      });
    }
    ,
    details(i) {
      // let url = ''
      // switch (i.type) {
      //   case 1:
      //     url = i.link
      //     break
      //   case 2:
      //     return
      //     break
      //   case 3:
      //     url = i.content.imageLink
      //     break
      // }
      //
      // this.$router.push({
      //   path: '/medium/detail',
      //   query: {url}
      // })
    }
    ,

    // 獲取數據
    getList() {
      radarIndex({
        type: this.active + 1,
        searchStr: this.serchValue || '',
        page: 1,
        perPage: this.perPage,
      }).then(res => {
        this.listData = res.data.list
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-tabs__line {
  bottom: 9px;
  margin-top: 0;
}

/deep/ .van-tabs__nav--line {
  padding-bottom: 1px;
}

/deep/ .van-tab__text {
  margin-bottom: 10px;
}

/deep/ .van-tab {
  background: #ffffff;
}

/deep/ .van-tabs__nav {
  background: #F1f2f5;
}

/deep/ .van-empty__image img {
  object-fit: contain;
}

/deep/ .van-dropdown-menu__bar {
  height: 36px;
}

/deep/ .van-ellipsis {
  font-size: 13px;
}

/deep/ .van-action-sheet__description {
  font-size: 16px;
  color: #444;
}

.index {
  font-size: 14px;
  height: 100%;
  background: #f2f4f8;

  p {
    margin: 0 !important;
  }

  .fs17 {
    font-size: 15px;
  }

  .fs13 {
    font-size: 12px;
  }

  .fs14 {
    font-size: 14px;
  }

  .title-serch {
    display: flex;
    align-items: center;
    justify-content: space-between;

  }

  .bet {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .checkMarkting {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 18px 10px 18px;

    .info {
      .title {
        color: #000;
        margin-bottom: 5px;
      }

      .infotxt {
        color: #8c8c8c;
        line-height: 21px;
        flex-grow: 1;
      }

      .more {
        width: 16px;
        height: 16px;
        margin-right: 20px;

        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  .share-icon {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 12px 10px;

    div {
      display: flex;
      flex-direction: column;
      /* justify-content: center; */
      align-items: center;
      color: #797979;
    }
  }

  .content {
    background: #f2f4f8;
    min-height: 92%;
    margin-top: -15px;
    //padding-top: 5px;
    img {
      width: 22px;
      height: 22px;
    }

    .van-hairline--bottom {
      &::after {
        border-bottom-width: 1px;
        position: absolute;
        box-sizing: border-box;
        content: " ";
        pointer-events: none;
        top: -50%;
        right: -50%;
        bottom: -50%;
        left: -50%;
        border: 0 solid #f2f4f8;
        -webkit-transform: scale(.5);
        transform: scale(.5);
      }
    }

    .item {
      padding: 12px 16px;
      width: 100%;

      .title {
        width: 100%;
        font-size: 12px;

        div:nth-child(1) {
          color: #444;
          font-size: 12px;
        }

        img {
          width: 14px;
          height: 14px;
        }
      }

      .item-body {
        .body-item {
          background: #f4f5f7;
          padding: 18px 15px;
          margin-top: 10px;
          border-radius: 12px;
          font-size: 12px;
          color: #585757;

          div {
            margin-bottom: 5px;
          }

          div:nth-child(1) {
            color: #444;
            margin-bottom: 10px;

            span {
              background: #c277ff;
              color: #fff;
              padding: 2px 3px;
              border-radius: 5px;
              font-size: 12px;
              margin-right: 9px;
            }
          }

          div:last-child {
            margin-top: 10px;
            color: #939393;
          }
        }
      }

      .items {
        background: #fff;
        border-radius: 12px;
        padding: 13px 13px;
        margin-bottom: 10px;

        .title {
          display: flex;

          img {
            width: 60px;
            height: 60px;
          }

          .share {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-left: 10px;
            height: 50px;

            div:nth-child(1) {
              color: #444;
              font-weight: 530;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              width: 254px;
            }

            div:nth-child(2) {
              color: #ccc;
              font-size: 12px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              width: 254px;
            }

          }
        }

        .share-button {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 12px;

          div:nth-child(1) {
            font-size: 12px;
            color: #9b9b9b;
          }

          div:nth-child(2) {
            font-size: 12px;
            background: #f0f4ff;
            color: #1890ff;
            padding: 1px 7px;
            border-radius: 15px;
            display: flex;
            align-items: center;

            img {
              width: 12px;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}
</style>
