<template>
  <div class="component">
    <div>
      <van-uploader :after-read="afterRead" accept="application/*" v-model="fileList"  :max-count="1"
                    multiple/>
    </div>
  </div>
</template>

<script>
import { upload } from '../../../api/contactWelcome'

export default {
  data() {
    return {
      fileList: [],
      accept: '.pdf'
    }
  },
  watch: {},
  methods: {
    get(type){
      switch (Number(type)){
        case 4:
          this.accept = '.amr'
              break;
        case 5:
          this.accept = '.mp4'
          break;
        case 7:
          this.accept = '*'
          break;
      }
    },
    setFile(data){
      this.fileList = [data]
    },
    // 文件大小
    onOversize(file) {
      this.$message.warning('文件大小不能超过 2MB');
    },
    // 上传文件
    afterRead(file) {
      var formData = new FormData();
      formData.append('file', file.file);
      upload(formData).then(res => {
        // this.items.title = res.data.name
        this.$emit('change',{
          url: res.data.fullPath,
          path: res.data.path,
          name: res.data.name
        })
      })

    }

  },
  mounted() {
    this.$nextTick(function() {
      this.$on('setFile', function(data) {
        this.setFile(data)
      });
    });
  },
}
</script>

<style lang="less" scoped>
/deep/ .van-empty__image {
  width: 129px !important;
  height: 115px !important;
}

/deep/ .van-action-sheet__header {
  font-weight: bolder;
}

.component {
  .content {
    padding: 0;

    .ttips {
      /deep/ .van-icon {
        font-size: 14px !important;
        color: #1890ff !important;
        margin-right: 5px !important;
      }

      font-size: 12px;
      padding: 5px 14px;
      background: #effaff;
      margin: 7px 7px;
    }

    .user-list {
      height: 275px;
      overflow: auto;

      .user {
        display: flex;
        align-items: center;
        justify-content: space-between;

        div {
          font-size: 14px;
          color: #252528;

          img {
            width: 46px;
            height: 46px;
            margin-right: 6px;
          }
        }
      }

      .active-background {
        background: #f6fbff !important;
      }
    }
  }
}
</style>
