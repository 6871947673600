<template>
  <div class="index">

    <!--  标题  -->
    <div style="margin-bottom: 30px;">

      <div style="overflow:hidden;padding: 12px 12px 0;margin-bottom: -12px;">
<!--        <van-cell @click="$refs.group.$show()" v-model="group.name" required-->
<!--                  style="padding: 16px;font-size: 14px !important;" title="选择分组">-->
<!--          <template #right-icon>-->
<!--            <van-icon style="margin-left: 10px;font-size: 16px;line-height: inherit" @click.stop="clearGroup"-->
<!--                      v-if="group.name != '全部分组'" name="clear"/>-->
<!--          </template>-->
<!--        </van-cell>-->
        <van-cell required style="padding: 16px;font-size: 14px !important;margin-top: 12px" title="雷达标题">
          <van-field
              v-model="title"
              placeholder="请输入雷达标题"
              input-align="right"
          />
        </van-cell>
      </div>

      <!--  文字  -->
      <div v-if="type == 1" class="content">
        <van-cell required style="padding: 16px;font-size: 14px !important;margin-top: 12px" title="雷达链接">
          <van-field
              v-model="link"
              placeholder="请输入雷达链接"
              input-align="right"
          />
        </van-cell>
        <van-cell required style="padding: 16px;font-size: 14px !important;margin-top: 12px" title="链接标题">
          <van-field
              v-model="link_title"
              placeholder="请输入链接标题"
              input-align="right"
          />
        </van-cell>
        <div style="display: block" class="first-step">
          <div class="form-item block nextBlock" style="margin-top: 12px;">
            <div class="form-label require" style="margin-bottom: 11px;position: relative;
    display: inline-block;">内容
            </div>
            <div class="form-content">
              <div class="url-input van-cell van-field">
                <div class="van-cell__value van-cell__value--alone van-field__value">
                  <div class="van-field__body" style="height: 100%;">
                <textarea v-model="link_description" maxlength="200" rows="2" placeholder="请输入" class="van-field__control"
                          style="height: 100%;">
                </textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-item block nextBlock" style="margin-top: 12px;">
          <div class="form-label require" style="margin-bottom: 11px;position: relative;
    display: inline-block;">链接封面
          </div>
          <div @click="get" class="form-content">
            <upload @change="getFile" ref="upload"/>
          </div>
        </div>
      </div>
      <!--  PDF  -->
      <div v-if="type == 2" class="content">
        <div style="display: block" class="first-step">
          <div class="form-item block nextBlock" style="margin-top: 12px;">
            <div class="form-label require" style="margin-bottom: 11px;position: relative;
    display: inline-block;">雷达PDF
            </div>
            <div @click="get" class="form-content">
              <uploadPDF @change="getFile" ref="upload"/>
            </div>
          </div>
        </div>
      </div>
      <!--  文章  -->
      <div v-if="type == 3" class="content">
        <div style="display: block" class="first-step">
          <div class="form-item block nextBlock" style="margin-top: 12px;">
            <div class="form-label require" style="margin-bottom: 11px;position: relative;
    display: inline-block;">雷达文章
            </div>
            <div class="form-content">
              <div class="url-input van-cell van-field">
                <div class="van-cell__value van-cell__value--alone van-field__value">
                  <div class="van-field__body" style="height: 100%;">
                <textarea v-model="link_description" maxlength="200" rows="2" placeholder="请输入" class="van-field__control"
                          style="height: 100%;">
                </textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <van-button @click="establish" block color="#2b60dd" style="padding: 0 20px;margin-top: 20px;border-radius: 7px;    width: 90%;
    margin: 0 auto;">
      下一步
    </van-button>

    <!--  分组  -->
    <group ref="group" @change="(e)=>group = e"/>
  </div>
</template>

<script>
import upload from "./components/upload";
import uploadPDF from "./components/uploadPDF";
import group from "./components/group";
import {medium, mediumShow, mediumUpdate} from "../../api/medium";
import {radar, radarInfo, radarUpdate} from "../../api/radar";

export default {
  components: {
    upload,
    uploadPDF,
    group
  },
  data() {
    return {
      id: "",
      value: "",
      // 类型
      type: null,
      //  分组
      group: {
        name: '全部分组',
        id: 0
      },
      //  标题
      title: '',
      //  链接
      link:'',
      link_title:'',
      link_description: "",
      //  文件
      file: {},
      tag_status:0,
      dynamic_notice:0,
      action_notice:0,
    }
  },
  watch: {
    group(i, v) {
      if (!i.name) {
        this.group = {
          name: '全部分组',
          id: 0
        }
      }
    }
  },
  methods: {
    details() {
      const that = this
      radarInfo({
        id: this.id || 751
      }).then(res => {
        console.log(res)
        const data = res.data
        this.title = data.title
        this.type = data.type

        switch (this.type) {
          case 1:
            this.link = data.link
            this.link_title = data.linkTitle
            this.link_description = data.linkDescription
            this.link_cover = data.linkCover
            var file = {
              url: data.pullLinkCover,
              path: data.linkCover,
              name: data.linkCover.split("/").reverse()[0]
            }
            this.file = file
            setTimeout(() => {
              that.$refs.upload.setFile(file)
            }, 10)
            break;
          case 2:
            // this
            var file = {
              url: data.pdf,
              path: data.short_pdf,
              name: data.pdfName
            }
            this.file = file
            setTimeout(() => {
              that.$refs.upload.setFile(file)
            }, 10)
            break;
          case 3:
            this.link_description = data.article.url
            break;
        }
      })
    },
    onClickLeft() {
      this.$router.back()
    },
    clearGroup() {
      this.group = {
        name: '全部分组',
        id: 0
      }
    },
    get() {
      this.$refs.upload.get(this.type)
    },
    getFile(e) {
      this.file = e
    },
    establish() {
      if (!this.title) {
        this.$message.error('雷达标题不能为空')
        return false
      }

      if(this.type == 1){
        if (!this.link) {
          this.$message.error('雷达链接不能为空')
          return false
        }

        if (!this.link_title) {
          this.$message.error('链接标题不能为空')
          return false
        }

        if (!this.link_description) {
          this.$message.error('链接摘要不能为空')
          return false
        }

        if (!this.file.path) {
          this.$message.error('链接封面不能为空')
          return false
        }

        const rep = /^(((ht|f)tps?):\/\/)?[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/
        if (!rep.test(this.link)) {
          this.$message.error('雷达链接不正确')
          return false
        }
      }else if(this.type == 2){
        if (!this.file.path) {
          this.$message.error('请上传PDF文件')
          return false
        }
      }else {
        if (!this.link_description) {
          this.$message.error('雷达文章不能为空')
          return false
        }
      }
      let data = {
        type: this.type,
        title : this.title,
        action_notice: this.action_notice,
        dynamic_notice: this.dynamic_notice,
        tag_status: this.tag_status,
      }
      switch (Number(this.type)) {
        case 1:
          data.link = this.link
          data.link_title = this.link_title
          data.link_description = this.link_description
          data.link_cover = this.file.path
          break
        case 2:
          data.pdf = this.file.url
          data.pdf_name = this.file.name
          break
        case 3:
          data.article_type =  1;
          data.article = {
            url: this.link_description
          }
          break
      }
      console.log(data)
      if(!this.id) {
        radar(data).then(res => {
          this.$message.success('创建成功')
          this.$router.back()
        })
      }else{
        data.id = Number(this.id)
        radarUpdate(data).then(res => {
          this.$message.success('修改成功')
          this.$router.back()
        })
      }

    }
  },
  created() {
    if (this.$route.query.type || this.$route.query.id) {
      if (this.$route.query.type) {
        this.type = this.$route.query.type
      } else {
        this.id = this.$route.query.id
        this.details()
      }
    } else {
      this.$router.back()
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-cell {
  padding: 0;
}

/deep/ .van-tabs__line {
  bottom: 9px;
  margin-top: 0;
}

/deep/ .van-tabs__nav--line {
  padding-bottom: 1px;
}

/deep/ .van-tab__text {
  margin-bottom: 10px;
}

/deep/ .van-tab {
  background: #ffffff;
}

/deep/ .van-tabs__nav {
  background: #F1f2f5;
}

/deep/ .van-empty__image img {
  object-fit: contain;
}

/deep/ .van-dropdown-menu__bar {
  height: 36px;
}

/deep/ .van-ellipsis {
  font-size: 13px;
}

/deep/ .van-cell__title {
  span {
    font-size: 14px;
  }
}

/deep/ .van-cell-group, .van-cell {
  border-radius: 7px;
}

.index {
  font-size: 14px;
  height: 100%;
  background: #f2f4f8;

  p {
    margin: 0 !important;
  }

  .fs17 {
    font-size: 17px;
  }

  .fs14 {
    font-size: .14px;
  }

  .content {
    background: #f2f4f8;

    padding: 0 12px;
    padding-top: 12px;
    //padding-bottom: 125px;
    //padding-top: 5px;
    .url-input {
      font-size: 16px;
      color: #ccc;
      min-height: 151px;
      border-radius: 4px;
      background-color: #f8f9fb;
      padding: 13px 16px;
      line-height: 20px;
    }

    img {
      width: 22px;
      height: 22px;
    }

    /deep/ .van-cell::after {
      position: relative;
    }

    .tips {
      font-size: 12px;
      color: #999;
      line-height: 17px;
      padding-left: 12px;
      margin-top: 12px;
    }

    .upload {
      width: 75px;
      height: 75px;
      background: #fff;
      border-radius: 4px;
      border: 1px solid #e7ebf0;
      color: #1890ff;
      font-size: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .fixed {
      position: fixed;
      bottom: 0;
      height: 72px;
      width: 100%;
      background: #fff;
      left: 0;
      padding: 12px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      div:nth-child(1) {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        margin-left: 13px;
      }

      div:nth-child(2) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: .9;
        margin-right: 17px;
      }
    }


    .require {
      &::after {
        content: "*";
        position: absolute;
        top: 2px;
        left: -12.5px;
        color: #e76d74;
        font-size: 16px;
        display: inline-block;
      }
    }

    .form-item {
      padding: 16px;
      display: block;
      background-color: #fff;
      border-radius: 7px;

      .form-label {
        font-size: 14px;
        color: #545454;
        line-height: 24px;
        margin-bottom: 4px;
        margin-left: 10px;
      }

      .form-content {
        font-size: 16px;
        color: #999;
        line-height: 26px;
      }
    }


  }
}
</style>
