<template>
  <div class="index">
    <div class="top-tabs">
      <div class="item" :class="roomDate == 'today'?'active':''" @click="changeTabs('today')">今日</div>
      <div class="item" :class="roomDate == 'sevenday'?'active':''" @click="changeTabs('sevenday')">近7天</div>
      <div class="item" :class="roomDate == 'thirtyday'?'active':''" @click="changeTabs('thirtyday')">近30天</div>
    </div>
    <div class="content" style="overflow:hidden;">
      <div class="basecard">
        <div class="header">
          <p class="header__title">数据走势</p>
        </div>
        <div class="databox">
          <div class="info-detail acea-row row-middle row-between">
            <div class="acea-row row-middle" v-if="info.type  == 1">
              <img :src="'https://api.chinainnet.com/static/'+info.linkCover" style="width: 50px;height: 50px">
              <div style="margin-left: 10px;max-width: 230px">{{ info.linkTitle }}</div>
            </div>
            <div class="acea-row row-middle" v-if="info.type  == 2">
              <img src="../../assets/images/pdf.png" style="width: 50px;height: 50px">
              <div style="margin-left: 10px;max-width: 230px">{{ info.pdfName }}</div>
            </div>
            <div class="acea-row row-middle" v-if="info.type  == 3">
              <img :src="info.article.cover_url" style="width: 50px;height: 50px">
              <div style="margin-left: 10px;max-width: 230px">{{ info.article.title }}</div>
            </div>
            <van-icon name="arrow"/>
          </div>
        </div>
      </div>
      <div class="basecard mgt16">
        <van-tabs v-model="active" swipe-threshold="7" color="#1890ff"
                  title-active-color="#1890ff">
          <van-tab :title="'发送员工('+roomStatistics.employee_count+'人)'">
            <a-table style="margin-top: -10px" :columns="columns" :pagination="false" :data-source="roomStatistics.employee_detail" :locale="locale">
              <div slot="name" slot-scope="text,record">
                <img :src="record.employeeAvatar" style="width: 20px;height: 20px">
                <span>{{record.employeeName}}</span>
              </div>
            </a-table>
          </van-tab>
          <van-tab :title="'访问客户('+roomStatistics.user_count+'人)'">
            <a-table style="margin-top: -10px" :columns="columns1"  :pagination="false" :data-source="roomStatistics.user_detail" :locale="locale">
              <div slot="name" slot-scope="text,record">
                <img :src="record.avatar" style="width: 20px;height: 20px">
                <span>{{record.nickname}}</span>
              </div>
            </a-table>
          </van-tab>
        </van-tabs>
        <div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {InfoDetail, radarInfo} from '../../api/radar'

const columns = [
  {
    title: '员工名称',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    scopedSlots: {customRender: 'name'},
  },
  {
    title: '发送次数',
    align: 'center',
    dataIndex: 'send_count',
    key: 'send_count',
  },
];


const columns1 = [
  {
    title: '客户名称',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    scopedSlots: {customRender: 'name'},
  },
  {
    title: '访问次数',
    align: 'center',
    dataIndex: 'visit_count',
    key: 'visit_count',
  },
];


export default {
  data() {
    return {
      columns,
      columns1,
      locale:{
        emptyText: '暂无数据'
      },
      // tbas切换
      active: '1',
      radar_id: '',
      roomDate: 'today',
      page:1,
      roomStatistics: {},
      pagination: {
        total: 0,
        current: 1,
        pageSize: 1000,
        showSizeChanger: true
      },
      info:{
      }
    }
  },
  watch: {
    active(e) {
      const that = this
      if (e == '2') {
        setTimeout(function () {
          that.categoryCategory()
        }, 1)
      }
    }
  },
  methods: {
    onClickLeft() {
      this.$router.back()
    },
    changeTabs(e) {
      this.roomDate = e;
      this.customerBase()
    },
    // 日期格式化
    dateFormat(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + '-';
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
      return Y + M + D
    },
    customerBase() {
      InfoDetail({time: this.roomDate, radar_id: this.radar_id,page:this.pagination.current,perPage:this.pagination.pageSize}).then(res => {
        this.roomStatistics = res.data
      })
    },
    details() {
      const that = this
      radarInfo({
        id: this.radar_id || 751
      }).then(res => {
        this.info = res.data
      })
    },
  },
  mounted() {
    var that = this
    that.radar_id = that.$route.query.id
    this.details()
    that.customerBase()
  },
}
</script>

<style lang="less" scoped>
/deep/ .ant-pagination {
  display: none;
}

/deep/ .van-tabs__line {
  bottom: 9px;
}

/deep/ .van-tabs__nav--line {
  padding-bottom: 8px;
}

/deep/ .van-tab__text {
  margin-bottom: 10px;
}

/deep/ .van-tab {
  background: #ffffff;
}

/deep/ .van-tabs__nav {
  background: #F1f2f5;
}

/deep/ .van-empty__image img {
  object-fit: contain;
}

.info-detail {
  width: 100%;
  height: auto;
  padding: 10px;
  background: #EEEEEE;
  border-radius: 10px;
}

.top-tabs {
  width: 100%;
  height: auto;
  padding: 6px 16px;
  background: #3a75c4;
  display: flex;
  align-items: center;

  .item {
    color: #FFFFFF;
    font-size: 14px;
    padding: 2px 8px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
  }

  .active {
    background: #8cbfee;
  }
}

.index {
  font-size: 14px;
  height: 100%;
  background: #fff;

  p {
    margin: 0 !important;
  }

  .fs17 {
    font-size: 17px;
  }

  .fs14 {
    font-size: .14px;
  }

  .content {
    background: #fff;
    min-height: 92%;
    padding-top: 5px;

    .time {
      font-weight: bolder !important;
      color: #444 !important;
    }

    img {
      width: 22px;
      height: 22px;
    }

    .mgt16 {
      margin-top: 8px;
    }

    .basecard {
      background-color: #fff;
      padding: 0 0 24px 0;

      .header {
        height: 65px;
        padding: 0 15px 0 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .header__title {
          line-height: 21px;
          font-weight: 500;
          color: #000;
        }

        .header__right {
          flex: 1;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .svg-icon {
            vertical-align: -0.15em;
            fill: currentColor;
            overflow: hidden
          }

          .switch {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .switch__item--active {
              font-weight: 500;
              color: #000;
            }

            .switch__item {

              padding: 0 3px;
              margin-left: 7px;
              color: #8c8c8c;
              font-size: 12px;
            }
          }
        }

      }

      .databox {
        padding: 0 16px;

        .list {
          border-radius: 15px;
          padding: 36px 0;
          overflow: hidden;
          display: flex;
          justify-content: space-around;
          align-items: center;
          flex-wrap: wrap;
          padding-top: 30px;
          padding-bottom: 30px;

          .list__item__desc {
            font-size: 11px;
            line-height: 16px;
            color: #545454;
          }

          .list__item__count {
            font-size: 20px;
            line-height: 21px;
            margin-bottom: 4px;
            font-weight: 500;
            color: #000;
            text-shadow: 0 3px 6px rgb(0 0 0 / 16%);
          }

          .mgb40 {
            margin-bottom: 20px;
          }

          .list__item {
            width: 30%;
            text-align: center;
          }
        }

        .customer {

        }
      }

      .chart__box {
        position: relative;
        height: 250px;

        .charts__tips {
          font-size: 10px;
          padding: 5px 16px 0;
          text-align: right;
          color: #b2b2b2;
        }
      }

      .content {
        padding: 0 16px;
        background-color: #fff;

        .rank {
          .header {
            position: relative;
            padding: 18px 28px 18px 26px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            line-height: 24px;
            font-weight: 500;
            border-radius: 15px 15px 0 0;
            color: #fff;
            background-color: #294ba3;

            &:after {
              content: " ";
              width: 0;
              height: 0;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              border-bottom: 8px solid #fff;
              position: absolute;
              bottom: 0;
              left: 79px;
            }

            .header__title {
              line-height: 0.56rem;
              font-weight: 500;
              color: #fff;
            }

            .header__time {
              padding-left: 32px;
              position: relative;

              &:after {
                border-left-width: 1px !important;
                position: absolute;
                box-sizing: border-box;
                content: " ";
                pointer-events: none;
                top: -50%;
                right: -50%;
                bottom: -50%;
                left: -50%;
                border: 0 solid #f2f4f8;
                -webkit-transform: scale(.5) !important;
                transform: scale(.5);
              }
            }
          }
        }
      }

      .yesterdayAdd {
        padding: 13px 15px;
        min-height: 120px;
        border: 1px solid #f2f1f1;
        border-radius: 0 0 16px 16px;
        color: #000;
        font-size: 14px;

        div {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
  }
}
</style>
