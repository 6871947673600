<template>
  <div class="index">
    <div class="top-tabs">
      <div class="item" :class="roomDate == 'today'?'active':''" @click="changeTabs('today')">今日</div>
      <div class="item" :class="roomDate == 'sevenday'?'active':''" @click="changeTabs('sevenday')">近7天</div>
      <div class="item" :class="roomDate == 'thirtyday'?'active':''" @click="changeTabs('thirtyday')">近30天</div>
    </div>
    <div class="content" style="overflow:hidden;">
      <div class="basecard">
        <div class="header">
          <p class="header__title">数据走势</p>
          <!--          <div class="header__right" style="flex: none;">-->
          <!--            <div class="switch">-->
          <!--              <p @click="customerBase(false)" :class="{ time: !roomDate }" class="switch__item switch__item&#45;&#45;active">-->
          <!--                近7日-->
          <!--              </p>-->
          <!--              <p @click="customerBase(true)" :class="{ time: roomDate }" class="switch__item">近30天</p></div>-->
          <!--          </div>-->
        </div>
        <div class="databox">
          <div style="padding-top: 15px;padding-bottom: 15px;" class="list customer van-hairline--surround">
            <div style="width: 50%;" class="list__item mgb40">
              <p class="list__item__count">{{ roomStatistics.total_send_count }}</p>
              <p class="list__item__desc">发送次数</p></div>
            <div style="width: 50%;" class="list__item mgb40">
              <p class="list__item__count">{{ roomStatistics.total_visit_count }}</p>
              <p class="list__item__desc">访问次数</p>
            </div>
          </div>
        </div>
        <div class="chart__box">
          <div class="Echarts" style='padding-left: 12px;'>
            <div id="myChart" :style="{ height: '300px'}"></div>
          </div>
        </div>
      </div>
      <div class="basecard mgt16">
        <div class="header"><p class="header__title">数据详情</p></div>
        <a-table
            :customRow="handleClickRow"
            :columns="columns" :data-source="data">
          <div slot="type" slot-scope="text">{{ text == 1 ? '链接' : text == 2 ? 'PDF' : '文章' }}</div>
          <div slot="action" slot-scope="text,record">
            <van-icon name="arrow" @click="$router.push({path:'/radar/totalDetail?type='+record.type})"/>
          </div>
        </a-table>
        <!--        <div class="content">-->
        <!--          <div class="rank">-->
        <!--            <div class="header"><p class="header__title fs17">昨日入群客户数</p>-->
        <!--              <p class="header__time fs14 van-hairline&#45;&#45;left">{{ yesterday }}</p></div>-->
        <!--            <div class="yesterdayAdd" v-if="employee.length > 0">-->
        <!--              <div v-for="(item,index) in employeeRanking" :key="index">-->
        <!--                <p>{{ index + 1 }} {{ item.name }}</p>-->
        <!--                <span>{{ item.total }}</span>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--            <van-empty-->
        <!--                v-else-->
        <!--                class="custom-image"-->
        <!--                :image="require('../../assets/images/emptyimg.png')"-->
        <!--                description="暂无数据"-->
        <!--            />-->
        <!--          </div>-->
        <!--        </div>-->
        <div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {staticChart, staticChartType} from '../../api/radar'

const columns = [
  {
    title: '类型',
    dataIndex: 'type',
    key: 'type',
    align: 'center',
    scopedSlots: {customRender: 'type'},
  },
  {
    title: '发送次数',
    align: 'center',
    dataIndex: 'send_count',
    key: 'send_count',
  },
  {
    title: '访问次数',
    align: 'center',
    dataIndex: 'visit_count',
    key: 'visit_count',
  },
  {
    title: '',
    align: 'center',
    key: 'action',
    scopedSlots: {customRender: 'action'},
  },
];

export default {
  data() {
    return {
      columns,
      data: [],
      // tbas切换
      active: '1',
      seven: this.dateFormat(new Date() - (86400 * 7 * 1000)),
      thirty: this.dateFormat(new Date() - (86400 * 30 * 1000)),
      roomDate: 'today',
      employeeRanking: [],
      employee: [],
      roomStatistics: {},
      chartCategory: {
        date: [],
        add: [],
        loss: [],
        eoss: []
      },
      yesterday: this.dateFormat(new Date() - (86400 * 1 * 1000)),
      // 饼图
      pieDatas: [],
    }
  },
  watch: {
    active(e) {
      const that = this
      if (e == '2') {
        setTimeout(function () {
          that.categoryCategory()
        }, 1)
      }
    }
  },
  methods: {
    onClickLeft() {
      this.$router.back()
    },
    handleClickRow(record, index) {
      return {
        on: {
          click: () => {
            this.$router.push({path:'/radar/totalDetail?type='+record.type})
          }
        }
      }
    },
    changeTabs(e) {
      this.roomDate = e;
      this.customerBase()
      this.staticChartTypeApi()
    },
    categoryCategory() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('myChart'))
      // 绘制图表
      myChart.setOption({
        xAxis: {
          type: 'category',
          data: this.chartCategory.date
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: this.chartCategory.add,
            type: 'line',
            smooth: true
          },
          {
            data: this.chartCategory.loss,
            type: 'line',
            smooth: true
          }
        ]
      });
    },
    // 日期格式化
    dateFormat(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + '-';
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
      return Y + M + D
    },
    customerBase() {
      staticChart({time: this.roomDate}).then(res => {
        this.roomStatistics = res.data
        this.chartCategory = {
          date: res.data.xAxis,
          add: res.data.series[0].data,
          loss: res.data.series[1].data,
        }
        this.categoryCategory()
      })
    },
    staticChartTypeApi() {
      staticChartType({time: this.roomDate}).then(res => {
        this.data = res.data
      })
    }
  },
  mounted() {
    var that = this
    that.customerBase()
    setTimeout(function () {
      that.staticChartTypeApi()
    }, 1)
  },
}
</script>

<style lang="less" scoped>
/deep/ .ant-pagination {
  display: none;
}

/deep/ .van-tabs__line {
  bottom: 9px;
}

/deep/ .van-tabs__nav--line {
  padding-bottom: 8px;
}

/deep/ .van-tab__text {
  margin-bottom: 10px;
}

/deep/ .van-tab {
  background: #ffffff;
}

/deep/ .van-tabs__nav {
  background: #F1f2f5;
}

/deep/ .van-empty__image img {
  object-fit: contain;
}

.top-tabs {
  width: 100%;
  height: auto;
  padding: 6px 16px;
  background: #3a75c4;
  display: flex;
  align-items: center;

  .item {
    color: #FFFFFF;
    font-size: 14px;
    padding: 2px 8px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
  }

  .active {
    background: #8cbfee;
  }
}

.index {
  font-size: 14px;
  height: 100%;
  background: #fff;

  p {
    margin: 0 !important;
  }

  .fs17 {
    font-size: 17px;
  }

  .fs14 {
    font-size: .14px;
  }

  .content {
    background: #fff;
    min-height: 92%;
    padding-top: 5px;

    .time {
      font-weight: bolder !important;
      color: #444 !important;
    }

    img {
      width: 22px;
      height: 22px;
    }

    .mgt16 {
      margin-top: 8px;
    }

    .basecard {
      background-color: #fff;
      padding: 0 0 24px 0;

      .header {
        height: 65px;
        padding: 0 15px 0 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .header__title {
          line-height: 21px;
          font-weight: 500;
          color: #000;
        }

        .header__right {
          flex: 1;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .svg-icon {
            vertical-align: -0.15em;
            fill: currentColor;
            overflow: hidden
          }

          .switch {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .switch__item--active {
              font-weight: 500;
              color: #000;
            }

            .switch__item {

              padding: 0 3px;
              margin-left: 7px;
              color: #8c8c8c;
              font-size: 12px;
            }
          }
        }

      }

      .databox {
        padding: 0 16px;

        .list {
          border-radius: 15px;
          padding: 36px 0;
          overflow: hidden;
          display: flex;
          justify-content: space-around;
          align-items: center;
          flex-wrap: wrap;
          padding-top: 30px;
          padding-bottom: 30px;

          .list__item__desc {
            font-size: 11px;
            line-height: 16px;
            color: #545454;
          }

          .list__item__count {
            font-size: 22px;
            line-height: 21px;
            margin-bottom: 4px;
            font-weight: 500;
            color: #000;
          }

          .mgb40 {
            margin-bottom: 20px;
          }

          .list__item {
            width: 30%;
            text-align: center;
          }
        }

        .customer {

        }
      }

      .chart__box {
        position: relative;
        height: 250px;

        .charts__tips {
          font-size: 10px;
          padding: 5px 16px 0;
          text-align: right;
          color: #b2b2b2;
        }
      }

      .content {
        padding: 0 16px;
        background-color: #fff;

        .rank {
          .header {
            position: relative;
            padding: 18px 28px 18px 26px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            line-height: 24px;
            font-weight: 500;
            border-radius: 15px 15px 0 0;
            color: #fff;
            background-color: #294ba3;

            &:after {
              content: " ";
              width: 0;
              height: 0;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              border-bottom: 8px solid #fff;
              position: absolute;
              bottom: 0;
              left: 79px;
            }

            .header__title {
              line-height: 0.56rem;
              font-weight: 500;
              color: #fff;
            }

            .header__time {
              padding-left: 32px;
              position: relative;

              &:after {
                border-left-width: 1px !important;
                position: absolute;
                box-sizing: border-box;
                content: " ";
                pointer-events: none;
                top: -50%;
                right: -50%;
                bottom: -50%;
                left: -50%;
                border: 0 solid #f2f4f8;
                -webkit-transform: scale(.5) !important;
                transform: scale(.5);
              }
            }
          }
        }
      }

      .yesterdayAdd {
        padding: 13px 15px;
        min-height: 120px;
        border: 1px solid #f2f1f1;
        border-radius: 0 0 16px 16px;
        color: #000;
        font-size: 14px;

        div {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
  }
}
</style>
