<template>
  <div class="index">
    <div class="title-serch">
      <van-search
          style="flex: 1;"
          v-model="serchValue"
          show-action
          placeholder="输入标题搜索"
          shape="round"
          @search="onSearch"
          @cancel="onSearch"
      >
      </van-search>
    </div>
    <div class="content">
      <van-dropdown-menu active-color="#1890ff">
        <van-dropdown-item @change="getList" v-model="value1" :options="option1"/>
        <van-dropdown-item @change="getList" v-model="value2" :options="option2"/>
      </van-dropdown-menu>
      <div class="item">
        <div v-if="listData.length > 0">
          <a-table
              :customRow="handleClickRow" :columns="columns" :data-source="listData" :locale="locale" :pagination="pagination"
              @change="handleTableChange">
            <div slot="type" slot-scope="text,record">{{ text == 1?record.link_title:text == 2?record.pdf_name:record.article.title }}</div>
            <div slot="action" slot-scope="text,record">
              <van-icon name="arrow" @click="$router.push('/radar/totalDataDetail?id='+record.id)"/>
            </div>
          </a-table>
        </div>
        <van-empty
            v-else
            class="custom-image"
            :image="require('../../assets/images/emptyimg.png')"
            description="暂无数据"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {staticDetail} from "../../api/radar";

const columns = [
  {
    title: '标题',
    dataIndex: 'type',
    key: 'type',
    align: 'center',
    scopedSlots: {customRender: 'type'},
  },
  {
    title: '发送次数',
    align:'center',
    dataIndex: 'send_link_count',
    key: 'send_link_count',
  },
  {
    title: '访问次数',
    align:'center',
    dataIndex: 'visit_link_count',
    key: 'visit_link_count',
  },
  {
    title: '',
    align:'center',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
];

export default {
  data() {
    return {
      columns,
      locale:{
        emptyText: '暂无数据'
      },
      // 文字
      serchValue: '',
      // tbas切换
      value1: 1,
      value2: 'today',
      option1: [
        {text: '链接', value: 1},
        {text: 'PDF', value: 2},
        {text: '文章', value: 3},
      ],
      option2: [
        {text: '今天', value: 'today'},
        {text: '昨天', value: 'yesterday'},
        {text: '近7天', value: 'sevenday'},
        {text: '近30天', value: 'thirtyday'},
        {text: '本月', value: 'month'},
        {text: '本年', value: 'year'},
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showSizeChanger: true
      },
      listData: [],
      fileId: '',
    }
  },
  watch: {},
  created() {
    this.value1 = parseInt(this.$route.query.type)
    console.log(this.value1)
    console.log(typeof this.value1)
    this.getList()
  },
  methods: {
    handleClickRow(record, index) {
      return {
        on: {
          click: () => {
            this.$router.push('/radar/totalDataDetail?id='+record.id)
          }
        }
      }
    },
    // 轨迹搜索
    onSearch() {
      this.getList()
    },
    onClickLeft() {
      this.$router.back()
    },
    show() {
      this.shows = true
    },
    onRefresh(e) {
    },
    onLoad() {

    },
    details(i) {
      let url = ''

      this.$router.push({
        path: '/medium/detail',
        query: {url}
      })
    },
    handleTableChange({current, pageSize}) {
      this.pagination.current = current
      this.pagination.pageSize = pageSize
      this.getList()
    },
    // 獲取數據
    getList() {
      staticDetail({
        type: this.value1,
        time: this.value2,
        title: this.serchValue || '',
        page: this.pagination.current,
        perPage: this.pagination.pageSize,
      }).then(res => {
        this.listData = res.data.list
        this.pagination.total = res.data.page.total
      })
    }
  }
}
</script>

<style>
.app {
  background: #FFFFFF;
}

</style>

<style lang="less" scoped>
/deep/ .van-tabs__line {
  bottom: 9px;
  margin-top: 0;
}

/deep/ .van-tabs__nav--line {
  padding-bottom: 1px;
}

/deep/ .van-tab__text {
  margin-bottom: 10px;
}

/deep/ .van-tab {
  background: #ffffff;
}

/deep/ .van-tabs__nav {
  background: #F1f2f5;
}

/deep/ .van-empty__image img {
  object-fit: contain;
}

/deep/ .van-dropdown-menu__bar {
  height: 36px;
}

/deep/ .van-ellipsis {
  font-size: 13px;
}

/deep/ .van-action-sheet__description {
  font-size: 16px;
  color: #444;
}

.index {
  font-size: 14px;
  height: 100%;
  background: #f2f4f8;

  p {
    margin: 0 !important;
  }

  .fs17 {
    font-size: 15px;
  }

  .fs13 {
    font-size: 12px;
  }

  .fs14 {
    font-size: 14px;
  }

  .title-serch {
    display: flex;
    align-items: center;
    justify-content: space-between;

  }

  .bet {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .checkMarkting {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 18px 10px 18px;

    .info {
      .title {
        color: #000;
        margin-bottom: 5px;
      }

      .infotxt {
        color: #8c8c8c;
        line-height: 21px;
        flex-grow: 1;
      }

      .more {
        width: 16px;
        height: 16px;
        margin-right: 20px;

        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  .share-icon {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 12px 10px;

    div {
      display: flex;
      flex-direction: column;
      /* justify-content: center; */
      align-items: center;
      color: #797979;
    }
  }

  .content {
    background: #FFFFFF;
    min-height: 92%;
    //padding-top: 5px;
    img {
      width: 22px;
      height: 22px;
    }

    .van-hairline--bottom {
      &::after {
        border-bottom-width: 1px;
        position: absolute;
        box-sizing: border-box;
        content: " ";
        pointer-events: none;
        top: -50%;
        right: -50%;
        bottom: -50%;
        left: -50%;
        border: 0 solid #f2f4f8;
        -webkit-transform: scale(.5);
        transform: scale(.5);
      }
    }

    .item {
      padding: 12px 0px;
      width: 100%;

      .title {
        width: 100%;
        font-size: 12px;

        div:nth-child(1) {
          color: #444;
          font-size: 12px;
        }

        img {
          width: 14px;
          height: 14px;
        }
      }

      .item-body {
        .body-item {
          background: #f4f5f7;
          padding: 18px 15px;
          margin-top: 10px;
          border-radius: 12px;
          font-size: 12px;
          color: #585757;

          div {
            margin-bottom: 5px;
          }

          div:nth-child(1) {
            color: #444;
            margin-bottom: 10px;

            span {
              background: #c277ff;
              color: #fff;
              padding: 2px 3px;
              border-radius: 5px;
              font-size: 12px;
              margin-right: 9px;
            }
          }

          div:last-child {
            margin-top: 10px;
            color: #939393;
          }
        }
      }

      .items {
        background: #fff;
        border-radius: 12px;
        padding: 13px 13px;
        margin-bottom: 10px;

        .title {
          display: flex;

          img {
            width: 60px;
            height: 60px;
          }

          .share {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-left: 10px;
            height: 50px;

            div:nth-child(1) {
              color: #444;
              font-weight: 530;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              width: 254px;
            }

            div:nth-child(2) {
              color: #ccc;
              font-size: 12px;
              width: 80px;
              display: flex;
              align-items: center;
              justify-content: space-between;
            }

          }
        }

        .share-button {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 12px;

          div:nth-child(1) {
            font-size: 12px;
            color: #9b9b9b;
          }

          div:nth-child(2) {
            font-size: 12px;
            background: #f0f4ff;
            color: #1890ff;
            padding: 1px 7px;
            border-radius: 15px;
            display: flex;
            align-items: center;

            img {
              width: 12px;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}
</style>
